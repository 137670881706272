// @flow strict
import React, { Component, useState } from 'react';
import { getContactHref, getIcon } from '../../../utils';
import Icon from '../../Icon';
import styles from './Contacts.module.scss';

type Props = {
  contacts: {
    [string]: string,
  },
};

const iconPosition = {
  spotify: 1,
  instagram: 2,
  twitter: 3,
  email: 4,
};

const getIconPos = (icon) => iconPosition[icon] || 10;
const iconSort = (x, y) => {
  const iconX = getIconPos(x);
  const iconY = getIconPos(y);
  if (iconX < iconY) {
    return -1;
  }
  if (iconX > iconY) {
    return 1;
  }
  return 0;
};

async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHover: false
    };
  }

  flashMessage = () => {
    this.setState({ showHover: true });
    setTimeout(() => {
      this.setState({ showHover: false });
    }, 3000);
  }

  clickMessage = (text) => {
    this.flashMessage();
    copyToClipboard(text);
  }

  getIconButton = (name, contacts) => {
    if (name === 'email') {
      return (
      <li className={styles['contacts__list-item']} key={name} onClick={this.clickMessage.bind(this, [getContactHref(name, contacts[name])])}>
        <div className={this.state.showHover ? styles['contacts__list-item-float'] : styles['contacts__list-item-none'] }>Email Address Copied</div>
        <a
          className={styles['contacts__list-item-link']}
          rel="noopener noreferrer"
          target="_blank"
        >
        <Icon name={name} icon={getIcon(name)} />
      </a>
    </li>
      );
    }
    return (<li className={styles['contacts__list-item']} key={name}>
      <a
        className={styles['contacts__list-item-link']}
        href={getContactHref(name, contacts[name])}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon name={name} icon={getIcon(name)} />
      </a>
    </li>);
  };

  render() {
    const { contacts } = this.props;
    return (
    <div className={styles['contacts']}>
    <ul className={styles['contacts__list']}>
      {Object.keys(contacts).sort(iconSort).map((name) => (!contacts[name] ? null
        : this.getIconButton(name, contacts)))}
    </ul>
  </div>
    );
  }
}

export default Contacts;
